import { cnf_event } from "../../config/Event_cnf";
import { IGameRoom } from "../../config/interface";
import UI_chips_list from "../../fgui/game/UI_chips_list";
import { notice } from "../../helper/NoticeHelper";
import BallDataManager from "../../model/BallDataManager";
import LocalUtil from "../../util/LocalUtil";
export class ChipsBtns {
    private ui_chips_list: UI_chips_list;
    private chips_list: any[] | any = []
    private selected_chips_money: number
    private roomData: IGameRoom = null
    constructor(ui_chips_list: UI_chips_list, roomData: IGameRoom) {
        this.ui_chips_list = ui_chips_list
        this.roomData = roomData
        this.onUILoaded()
    }
    onUILoaded() {
        BallDataManager.getInstance().chipsData = BallDataManager.getInstance().chipsData.filter((item: number) => {
            return item >= this.roomData.minAmount && item <= this.roomData.maxAmount
        })
        this.chips_list = LocalUtil.objectForKey("chips_selected_list", [])
        // 删除小于限红的和大于限红的
        this.chips_list = this.chips_list.filter((item: number) => {
            return item >= this.roomData.minAmount && item <= this.roomData.maxAmount
        })


        if (this.chips_list.length <= 0) {
            LocalUtil.setObject(BallDataManager.getInstance().chipsData, "chips_selected_list")
            this.chips_list = LocalUtil.objectForKey("chips_selected_list", [])
        }





        this.ui_chips_list.m_chips_list.itemRenderer = Laya.Handler.create(this, this.renderListItem, null, false);
        this.ui_chips_list.m_chips_list.numItems = this.chips_list.length;
        let selectedChipsInfo = LocalUtil.stringForKey('selectedChipsInfo')
        if (selectedChipsInfo) {
            BallDataManager.getInstance().setSelectedChipsInfo(JSON.parse(selectedChipsInfo))
        } else {
            this.toWorldSpaceAr(this.ui_chips_list.getChildAt(0), this.chips_list[0])
        }
        this.selected_chips_money = BallDataManager.getInstance().getSelectedChipsInfo().chipsMoney



        let i = this.chips_list.indexOf(this.selected_chips_money)
        if (i >= 0) {
            this.ui_chips_list.m_chips_list.scrollPane.scrollToView(this.ui_chips_list.m_chips_list.getChildAt(i));
            (this.ui_chips_list.m_chips_list.getChildAt(i) as fgui.GButton).selected = true
        }

        notice.on(cnf_event.NOTICE_UPDATE_CHIPS, this.setChips)

    }
    //当前选择筹码列表
    private renderListItem(index: number, obj: fgui.GObject): void {
        let item: fgui.GButton = <fgui.GButton>obj;
        item.icon = fgui.UIPackage.getItemURL("game", "chip_" + this.chips_list[index]);
        item.selected = this.selected_chips_money == this.chips_list[index]
        item.onClick(this, () => {
            this.selected_chips_money = this.chips_list[index]
            this.toWorldSpaceAr(item, this.chips_list[index])
        })
    }
    //转到世界坐标
    toWorldSpaceAr(node: fairygui.GButton, money: any) {
        let worldPos = node.localToGlobal(0, 0)
        let x = worldPos.x //- width
        let y = worldPos.y //- heigth
        worldPos.x = x
        worldPos.y = y
        let data = {
            chipsMoney: money,
            chipsPos: worldPos
        }
        BallDataManager.getInstance().setSelectedChipsInfo(data)
    }


    getChipsbtn(money: number) {
        let index = this.chips_list.findIndex((item: number) => {
            return item == money
        })
        if (index > -1) {
            return this.ui_chips_list.m_chips_list.getChildAt(index)
        } else {
            return null
        }
    }

    setChips = () => {
        this.chips_list = LocalUtil.objectForKey("chips_selected_list")
        // 删除小于限红的和大于限红的
        this.chips_list = this.chips_list.filter((item: number) => {
            return item >= this.roomData.minAmount && item <= this.roomData.maxAmount
        })
        this.ui_chips_list.m_chips_list.numItems = this.chips_list.length;
        this.selected_chips_money = BallDataManager.getInstance().getSelectedChipsInfo().chipsMoney
        let i = this.chips_list.indexOf(this.selected_chips_money)
        if (i >= 0) {
            this.ui_chips_list.m_chips_list.scrollPane.scrollToView(this.ui_chips_list.m_chips_list.getChildAt(i));
            (this.ui_chips_list.m_chips_list.getChildAt(i) as fgui.GButton).selected = true
        }
    }

    destroy() {
        notice.off(cnf_event.NOTICE_UPDATE_CHIPS, this.setChips)
        this.ui_chips_list.dispose()
    }

}