
import UI_message_tips from "../fgui/common/UI_message_tips";

class Helper {

    private windowsMap: Map<string, fgui.Window> = new Map();
    messageTips: UI_message_tips;


    constructor() {
        fgui.UIConfig.globalModalWaiting = "ui://1gj14ei7nojpg";
    }

    // 创建一个持续展示的loading
    showWaitModal(autoClose = true) {
        //这里模拟一个要锁住全屏的等待过程
        fgui.GRoot.inst.showModalWait();
        if (autoClose) {
            Laya.timer.once(6000, this, function (): void {
                fgui.GRoot.inst.closeModalWait();
            });
        }
    }

    closeWaitModal() {
        fgui.GRoot.inst.closeModalWait();
    }

    showMsg(msg: string, time = 2) {
        let tipsWindow: UI_message_tips
        if (this.messageTips) {
            tipsWindow = this.messageTips
        } else {
            tipsWindow = UI_message_tips.createInstance()
            fgui.GRoot.inst.addChild(tipsWindow)
            this.messageTips = tipsWindow
        }
        Laya.Tween.clearAll(tipsWindow)
        fgui.GRoot.inst.setChildIndex(tipsWindow, 999999)
        tipsWindow.m_message.text = msg
        tipsWindow.m_message.width = fgui.GRoot.inst.width
        tipsWindow.m_message.autoSize = 3;
        tipsWindow.setXY(fgui.GRoot.inst.width / 2 - tipsWindow.width / 2, fgui.GRoot.inst.height / 2 - tipsWindow.height / 2)
        tipsWindow.alpha = 0
        Laya.Tween.to(tipsWindow, { alpha: 1 }, time * 1000, Laya.Ease.elasticOut, Laya.Handler.create(this, () => {
            tipsWindow.alpha = 0
            fgui.GRoot.inst.setChildIndex(tipsWindow, 0)
        }));
    }

    closeMsg() {
   
    }








    showWindow(window: fgui.Window) {
        if (this.windowsMap.has(window._id)) {
            window.show()
        } else {
            this.windowsMap.set(window.id, window)
            window.show()
        }
    }

    closeWindow(window: fgui.Window) {
        window.hide()
        this.windowsMap.delete(window._id)
    }

    closeAllWindow() {
        this.windowsMap.forEach((w) => {
            this.closeWindow(w)
        })
    }

    createIFrame() {
        let div = document.createElement('div') as HTMLDivElement
        document.body.append(div)
        div.style.width = "100%"
        div.style.height = "100%"
        div.style.display = "none"
        div.id = "windowDIV"
        div.style.justifyContent = "center"
        div.style.alignItems = "center"
        div.style.position = "absolute"
        div.style.zIndex = "99999"
        div.style.backgroundColor = "rgba(0,0,0,0.5)"
     
        let iframe = document.createElement('iframe') as HTMLIFrameElement
        iframe.id = "windowIFrame"
        iframe.setAttribute("frameBorder","no")
        iframe.setAttribute("border","0")
        iframe.setAttribute("marginwidth","0")
        iframe.setAttribute("marginheight","0")
        iframe.setAttribute("scrolling","no")
        iframe.setAttribute("allowtransparency","yes")
        iframe.style.width = "100%"
        iframe.style.height = "100%"
        div.addEventListener("click",()=>{
            iframe.src = ""
            div.style.display = "none"
        })


        let closeButton = document.createElement("button") as HTMLButtonElement
        closeButton.innerText = "X"
        closeButton.style.position = "absolute"
        closeButton.style.top = "10px"
        closeButton.style.left = "10px"
        closeButton.style.width = "30px"
        closeButton.style.zIndex = "99999"
        closeButton.style.backgroundColor = "red"
        closeButton.style.color = "white"
        closeButton.style.border = "none"
        closeButton.style.padding = "5px"
        closeButton.style.cursor = "pointer"
        closeButton.addEventListener("click",()=>{
            iframe.src = ""
            div.style.display = "none"
        })
        div.appendChild(closeButton)

        div.appendChild(iframe)
    }

    showIFrame(url: string){
        let div = document.getElementById("windowDIV") as HTMLDivElement
        div.style.display = 'flex'
        let iframe = document.getElementById("windowIFrame") as HTMLIFrameElement
        iframe.src = url
    }
}

export const UIHelper: Helper = new Helper();