export const pt = {
  translation: {
    "netError": "Network error",
    "noService": 'You currently do not have a dedicated customer service',
    "agentID": 'Agent ID',
    "agentNick": 'Agent nickname:',
    "moneyAgent": 'Cash agent',
    "creditAgent": 'Credit agent share',
    "copySuccess": 'Copy successfully',
    "all": 'All',
    "settledBets": 'Settled bets',
    "unsettledBets": 'Unsettled bets',
    "lose": 'Lose',
    "win": 'Win',
    "inviteQR": 'Invite QR code',
    "unsettled": 'Unsettled',
    "settled": 'Settled',
    "canceled": 'Cancelled',
    "num": 'Number',
    "numName": 'Name',
    "anonymous": "Anonymous",
    "prizeDraw": 'Waiting for prize draw',
    "dragon": "Dragon",
    "tiger": "Tiger",
    "even": "Even",
    "odd": "Odd",
    "big": "Big",
    "small": "Small",
    "closing": 'About to close',
    "close": 'Closed',
    "opened": 'Opened',
    "hour": 'Hour',
    "min": 'Minute',
    "sec": 'Second',
    "tourTip": "The current account is a trial account, do you want to register?",
    "tip": "Tip",
    "loginMsg": "Incomplete login information",
    "regMsg": "Incomplete registration information",
    "maxImg": "Image size cannot exceed 5MB",
    "avatorError": "Failed to upload avatar",
    "noPayTip": "The current account has not set a payment password, do you want to set it?",
    "noBindTip": "The current withdrawal method is not bound, please bind the withdrawal method first",
    "noUSDTTip": "The current account has not bound a wallet address, do you want to bind it? ",
    "bind": "bind",
    "order": "deposit record",
    "withdraw": "withdrawal record",
    "userPassway": "manual recharge",
    "usdtPassway": "USDT recharge",
    "selectMoney": "please select a recharge amount",
    "input": "please enter",
    "user": 'User',
    "no": "não",
    "emailCode": 'code',
    "reEmailCode": 'code',
    "findPwd": 'Recuperar Senha',
    "backLogin": 'Voltar ao login',
    "AlipayLarge": "AlipayLarge",
    "plzBet":"Please bet",
    "openMusic":"Open music",
    "closeMusic":"Close music",
  }
}