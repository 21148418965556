/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_game_btn from "./UI_game_btn";

export default class UI_Chat extends fgui.GComponent {

	public m_list:fgui.GList;
	public m_game:UI_game_btn;
	public m_toggleCom:fgui.GButton;
	public m_input1:fgui.GTextInput;
	public m_btnSend1:fgui.GButton;
	public static URL:string = "ui://q1kq7fv1reg6j";

	public static createInstance():UI_Chat {
		return <UI_Chat>(fgui.UIPackage.createObject("chat", "Chat"));
	}

	protected onConstruct():void {
		this.m_list = <fgui.GList>(this.getChild("list"));
		this.m_game = <UI_game_btn>(this.getChild("game"));
		this.m_toggleCom = <fgui.GButton>(this.getChild("toggleCom"));
		this.m_input1 = <fgui.GTextInput>(this.getChild("input1"));
		this.m_btnSend1 = <fgui.GButton>(this.getChild("btnSend1"));
	}
}