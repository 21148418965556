import { E_ScreenMode } from "../../config/Enum";
import ConfigManager from "../../helper/ConfigManager"
import { http } from "../../net/Http"
import { Router } from "../../util/router"
import { IPConifg } from "../../config/NetConfig"
import { IAgent,ISubAgentInfo } from "../../config/interface"
import { UIHelper } from "../../helper/UIHelper";
import { UIBase } from "../scene/UIBase";
import { CustomerWindow } from "../windows/CustomerWindow";
import UI_agent from "../../fgui/agent/UI_agent";
import UI_avatar from "../../fgui/common/UI_avatar";
import UI_agent_item from "../../fgui/agent/UI_agent_item";
import I18nHelper from "../../helper/I18nHelper";
export class Agent extends UIBase {
    private uiagent: UI_agent
    subAgentInfo: ISubAgentInfo[] = []
    data: IAgent = null

    xhrList: XMLHttpRequest[] = []
    constructor(or: E_ScreenMode) {
        super(or == E_ScreenMode.horizontal ? UI_agent.createInstance() : UI_agent.createInstance());
        this.onUILoaded()
    }
    onUILoaded() {
        this.uiagent = this.UI_Item as UI_agent
        fgui.GRoot.inst.addChild(this.uiagent)

        this.uiagent.m_back.onClick(this, this.back)

        //客服列表
        this.uiagent.m_help_btn.onClick(this, this.onCustomerClick);
        this.uiagent.m_submitAgent1.onClick(this, this.onCustomerClick)
        this.uiagent.m_submitAgent0.onClick(this, this.applyAgent0)
        this.uiagent.m_linkBtn.onClick(this, this.onCopyClick)
        this.uiagent.m_qrBtn.onClick(this, this.downloadQRCode)

        this.uiagent.m_useList.itemRenderer = Laya.Handler.create(this, this.renderListItem, null, false)
        this.getData()
    }
    //客服列表
    customerWindow: CustomerWindow = null
    onCustomerClick() {
        if (!ConfigManager.getInstance().Customer) {
            UIHelper.showMsg(I18nHelper.t('noService'))
            return
        }
        if (ConfigManager.getInstance().Customer.length > 0) {
            if (!this.customerWindow) {
                this.customerWindow = new CustomerWindow(ConfigManager.getInstance().Customer)
            }
            UIHelper.showWindow(this.customerWindow)
        }
        else {
            UIHelper.showMsg(I18nHelper.t('noService'))
        }
    }

    renderListItem(index: number, obj: UI_agent_item) {
        obj.m_id.text = this.subAgentInfo[index].id.toString()
        obj.m_nickname.text = this.subAgentInfo[index].name.toString()
        obj.m_username.text = this.subAgentInfo[index].phone.toString()
        obj.m_per.text = this.subAgentInfo[index].per ? this.subAgentInfo[index].per.toString() : I18nHelper.t('no')

        obj.m_invitationCode.text = this.subAgentInfo[index].invitationCode ? this.subAgentInfo[index].invitationCode.toString() : I18nHelper.t('no')


        let arr = [I18nHelper.t('moneyAgent'), I18nHelper.t('creditAgent'), I18nHelper.t('user')]  //0-现金代理 1-授信代理 2-用户


        obj.m_isCredit.text = arr[this.subAgentInfo[index].type]

    }



    getData() {
        let xhr = http.userGetAgentInfo((res: any) => {
            console.log("代理信息", res);
            if (res.code == 200) {
                this.data = res.data
                this.reloadUI()

            } else {

                UIHelper.showMsg(I18nHelper.t(res.msg))
            }

            let xhr2 = http.getSubAgentAndUser((res: any) => {
                if (res.code == 200) {
                    this.subAgentInfo = res.data
                    this.uiagent.m_useList.numItems = this.subAgentInfo.length
                    this.reloadUI()
                } else {
                    UIHelper.showMsg(I18nHelper.t(res.msg))
                }
            })
            this.xhrList.push(xhr2)
        })

        this.xhrList.push(xhr)
    }

    reloadUI() {



        if (this.subAgentInfo.length > 0) {
            this.uiagent.m_noagenttop.visible = false
            this.uiagent.m_listTitle.visible = true
        } else {
            this.uiagent.m_noagenttop.visible = true
            this.uiagent.m_listTitle.visible = false
        }

        if (this.data.myAgentInfo) {
            this.uiagent.m_hasagent.visible = true
            this.uiagent.m_submitAgent0.visible = false
            this.uiagent.m_submitAgent1.visible = false

            this.uiagent.m_agentId_me.text = `${I18nHelper.t('agentID')}：${this.data.myAgentInfo.id.toString()}`
            this.uiagent.m_username_me.text = `${I18nHelper.t('agentNick')}${this.data.myAgentInfo.nickname.toString()}`
            if (this.data.myAgentInfo.isCredit == 0) {
                this.uiagent.m_odds.title = I18nHelper.t('moneyAgent')
            }

            if (this.data.myAgentInfo.isCredit == 1) {
                this.uiagent.m_odds.title = `${I18nHelper.t('creditAgent')}-${this.data.myAgentInfo.per}-${this.data.myAgentInfo.creditLimit}`
            }
            (this.uiagent.m_avatorMe as UI_avatar).m_avatar.url =  this.data.myAgentInfo.avatar


        } else {
            this.uiagent.m_hasagent.visible = false
            this.uiagent.m_submitAgent0.visible = false
            this.uiagent.m_submitAgent1.visible = false
            if (this.data.parentType == 1) {
                this.uiagent.m_submitAgent1.visible = true

            } else if (this.data.parentType == 0) {
                this.uiagent.m_submitAgent0.visible = true

            } else {

                this.uiagent.m_submitAgent0.visible = true
                this.uiagent.m_submitAgent1.visible = true
            }


        }

        if (this.data.inviteLink) {
            this.uiagent.m_hasLink.visible = true

            this.uiagent.m_qrcode.url = this.data.inviteCode
            this.uiagent.m_link.text = this.data.inviteLink

        } else {
            this.uiagent.m_hasLink.visible = false
        }




    }


    applyAgent0() {
        let xhr = http.applyAgent(0, (res: any) => {
            console.log("申请结果", res);
            UIHelper.showMsg(I18nHelper.t(res.msg))
        })
        this.xhrList.push(xhr)
    }

    applyAgent1() {
        let xhr = http.applyAgent(1, (res: any) => {
            UIHelper.showMsg(I18nHelper.t(res.msg))
            console.log("申请结果", res);

        })
        this.xhrList.push(xhr)
    }


    back() {
        Laya.Scene.open(Router.Me, true, null, Laya.Handler.create(this, () => {
        }))
    }


    // 复制并打开如微信之类
    protected onCopyClick(): void {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(this.data.inviteLink)
        } else {
            const input = document.createElement('textarea')
            input.value = this.data.inviteLink
            document.body.appendChild(input)
            input.select()
            document.execCommand('copy')
            document.body.removeChild(input)
        }
        UIHelper.showMsg(I18nHelper.t('copySuccess'))

    }


    downloadQRCode() {
        var base64ToBlob = function (code: string) {
            let parts = code.split(';base64,');
            let contentType = parts[0].split(':')[1];
            let raw = window.atob(parts[1]);
            let rawLength = raw.length;
            let uInt8Array = new Uint8Array(rawLength);
            for (let i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i);
            }
            return new Blob([uInt8Array], {
                type: contentType
            });
        };
        let aLink = document.createElement('a');
        let blob = base64ToBlob(this.data.inviteCode); //new Blob([content]);
        let evt = document.createEvent("HTMLEvents");
        evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
        aLink.download = I18nHelper.t('inviteQR');
        aLink.href = URL.createObjectURL(blob);
        aLink.click();
    }



    destroy() {
        this.xhrList.forEach((xhr) => {
            console.log("取消", xhr);

            xhr.abort()
        })
        this.uiagent.dispose()
    }
}