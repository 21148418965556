/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_btn from "./UI_btn";
import UI_Button5 from "./UI_Button5";

export default class UI_Login extends fgui.GComponent {

	public m_c1:fgui.Controller;
	public m_select_btn:UI_btn;
	public m_username:fgui.GTextInput;
	public m_authCode:UI_Button5;
	public m_phone:fgui.GGroup;
	public m_reg_password:fgui.GTextInput;
	public m_password:fgui.GTextInput;
	public m_psd:fgui.GGroup;
	public m_code:fgui.GTextInput;
	public m_code_img:fgui.GLoader;
	public m_cod:fgui.GGroup;
	public m_authCodeInput:fgui.GTextInput;
	public m_authNode:fgui.GGroup;
	public m_name:fgui.GTextInput;
	public m_name_box:fgui.GGroup;
	public m_registerBtn:fgui.GButton;
	public m_loginBtn:fgui.GButton;
	public m_fpasword:fgui.GButton;
	public m_trylog:fgui.GButton;
	public m_reg_password2:fgui.GTextInput;
	public m_repsd:fgui.GGroup;
	public m_loginBtn2:fgui.GButton;
	public m_loginBtn3:fgui.GButton;
	public m_form:fgui.GGroup;
	public m_logo:fgui.GLoader;
	public m_country_btn:fgui.GComboBox;
	public m_tourCode:fgui.GTextInput;
	public m_tourCode_img:fgui.GLoader;
	public m_cod1:fgui.GGroup;
	public m_tourLoginBtn:fgui.GButton;
	public m_backLoginBtn:fgui.GButton;
	public m_tour:fgui.GGroup;
	public static URL:string = "ui://00tbrg0lnffi7f";

	public static createInstance():UI_Login {
		return <UI_Login>(fgui.UIPackage.createObject("login", "Login"));
	}

	protected onConstruct():void {
		this.m_c1 = this.getController("c1");
		this.m_select_btn = <UI_btn>(this.getChild("select_btn"));
		this.m_username = <fgui.GTextInput>(this.getChild("username"));
		this.m_authCode = <UI_Button5>(this.getChild("authCode"));
		this.m_phone = <fgui.GGroup>(this.getChild("phone"));
		this.m_reg_password = <fgui.GTextInput>(this.getChild("reg_password"));
		this.m_password = <fgui.GTextInput>(this.getChild("password"));
		this.m_psd = <fgui.GGroup>(this.getChild("psd"));
		this.m_code = <fgui.GTextInput>(this.getChild("code"));
		this.m_code_img = <fgui.GLoader>(this.getChild("code_img"));
		this.m_cod = <fgui.GGroup>(this.getChild("cod"));
		this.m_authCodeInput = <fgui.GTextInput>(this.getChild("authCodeInput"));
		this.m_authNode = <fgui.GGroup>(this.getChild("authNode"));
		this.m_name = <fgui.GTextInput>(this.getChild("name"));
		this.m_name_box = <fgui.GGroup>(this.getChild("name_box"));
		this.m_registerBtn = <fgui.GButton>(this.getChild("registerBtn"));
		this.m_loginBtn = <fgui.GButton>(this.getChild("loginBtn"));
		this.m_fpasword = <fgui.GButton>(this.getChild("fpasword"));
		this.m_trylog = <fgui.GButton>(this.getChild("trylog"));
		this.m_reg_password2 = <fgui.GTextInput>(this.getChild("reg_password2"));
		this.m_repsd = <fgui.GGroup>(this.getChild("repsd"));
		this.m_loginBtn2 = <fgui.GButton>(this.getChild("loginBtn2"));
		this.m_loginBtn3 = <fgui.GButton>(this.getChild("loginBtn3"));
		this.m_form = <fgui.GGroup>(this.getChild("form"));
		this.m_logo = <fgui.GLoader>(this.getChild("logo"));
		this.m_country_btn = <fgui.GComboBox>(this.getChild("country_btn"));
		this.m_tourCode = <fgui.GTextInput>(this.getChild("tourCode"));
		this.m_tourCode_img = <fgui.GLoader>(this.getChild("tourCode_img"));
		this.m_cod1 = <fgui.GGroup>(this.getChild("cod1"));
		this.m_tourLoginBtn = <fgui.GButton>(this.getChild("tourLoginBtn"));
		this.m_backLoginBtn = <fgui.GButton>(this.getChild("backLoginBtn"));
		this.m_tour = <fgui.GGroup>(this.getChild("tour"));
	}
}