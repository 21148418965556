import { IRoomData } from "../../config/interface";
import UI_ball from "../../fgui/game/UI_ball";
import UI_prize from "../../fgui/game/UI_prize";
import HttpHelper from "../../helper/HttpHelper";
import I18nHelper from "../../helper/I18nHelper";
import { http } from "../../net/Http";

export class PrizeModel {
    private data: IRoomData = null
    private uiPrize: UI_prize
    private prizeData: number[] = []
    xhrList: XMLHttpRequest[] = [];
    deleyTimer: number = 0

    constructor(ui: UI_prize) {
        this.uiPrize = ui
        this.onUILoaded()
    }
    onUILoaded() {
        this.uiPrize.m_ball_list.itemRenderer = Laya.Handler.create(this, this.renderBallListItem, null, false)
        this.uiPrize.m_lastNperCode.setVar("lNperCode", 0)
        this.uiPrize.m_nperCode.setVar("nperCode", 0)
    }


  
    
    setData(data: IRoomData) {
        this.data = data

        this.uiPrize.m_lastNperCode.setVar("lNperCode", this.data.nperCode)
        this.uiPrize.m_nperCode.setVar("nperCode", this.data.nperCode)
        clearInterval(this.timer);
        if (this.data.resultStatus == 0) {
            this.times = data.seconds
            this.countDown()
        }
        if (this.data.resultStatus == 1) {
            this.uiPrize.m_time.text = I18nHelper.t('closing');
        }
        if (this.data.resultStatus == 2) {
            this.uiPrize.m_time.text = I18nHelper.t('close');
        }
        if (this.data.resultStatus == 3) {
            this.uiPrize.m_time.text = I18nHelper.t('opened');
        }
     
        
        this.deleyTimer = setTimeout(() => {
            let xhr =  http.newOpenLottery(data.roomId, (res: any) => {
                console.log("最新开奖结果,", res);
                if (res.code == 200) {
                    this.prizeData = JSON.parse(res.data.result)
                    this.uiPrize.m_ball_list.numItems = this.prizeData.length
                }
            })
            this.xhrList.push(xhr)
            HttpHelper.getInstance().getUserBalance()
        },2000)

    }


    // 剩余时间
    times: number = 180
    timer: number = null;

    countDown = () => {
        if (this.times <= 0) {
            clearInterval(this.timer);
            return
        }
        function addZero(i: number) {
            return i < 10 ? "0" + i : i;
        }

        this.timer = setInterval(() => {
            let day: string | number = parseInt((this.times / (24 * 60 * 60)).toString())
            let hour: string | number = parseInt((this.times / (60 * 60) % 24).toString());
            let minute: string | number = parseInt((this.times / 60 % 60).toString());
            let second: string | number = parseInt((this.times % 60).toString());
            day = addZero(day);
            hour = addZero(hour);
            minute = addZero(minute);
            second = addZero(second);

            if (this.data.resultStatus != 0) {
                this.uiPrize.m_time.text = '';
                if (this.data.resultStatus == 1) {
                    this.uiPrize.m_time.text = I18nHelper.t('closing');
                }
                if (this.data.resultStatus == 2) {
                    this.uiPrize.m_time.text = I18nHelper.t('close');
                }
                if (this.data.resultStatus == 3) {
                    this.uiPrize.m_time.text = I18nHelper.t('opened');
                    HttpHelper.getInstance().getUserBalance()
                }
                clearInterval(this.timer);
                return
            }

            if (this.times <= 0) {
                this.uiPrize.m_time.text = I18nHelper.t('close');
                clearInterval(this.timer);
                return
            } else {
                this.uiPrize.m_time.text = `${hour}${I18nHelper.t('hour')} ${minute}${I18nHelper.t('min')} ${second}${I18nHelper.t('sec')}`;
            }
            this.times--
        }, 1000)
    }



    renderBallListItem(index: number, obj: fairygui.GObject) {
        let ball = obj as UI_ball
        ball.m_ball_loader.url = fgui.UIPackage.getItemURL("game", "ball" + this.prizeData[index]);
    }


    destroy() {
        this.xhrList.forEach((xhr)=>{
            xhr.abort()
        })
        this.times = 0
        clearTimeout(this.deleyTimer)
        clearInterval(this.timer)
        this.uiPrize.dispose();
    }
}