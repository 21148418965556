import { E_ScreenMode } from "../../config/Enum";
import UI_Login from "../../fgui/login/UI_Login";
import ConfigManager from "../../helper/ConfigManager"
import HttpHelper from "../../helper/HttpHelper";
import I18nHelper from "../../helper/I18nHelper";
import { UIHelper } from "../../helper/UIHelper";
import GlobalParameterManager from "../../model/GlobalParameterManager";
import PlayerDataManager from "../../model/PlayerDataManager";
import { http } from "../../net/Http"
import NetManager from "../../net/NetManager"
import LocalUtil from "../../util/LocalUtil";
import { Router } from "../../util/router"
import { GameScene } from "../scene/GameScene";
import { UIBase } from "../scene/UIBase";
import { CustomerWindow } from "../windows/CustomerWindow";
export class Login extends UIBase {
    private reloadNums: number = 0
    private or: E_ScreenMode
    private uiLogin: UI_Login
    private c1: fgui.Controller;
    private uuid: string = ''
    private tourUuid: string = ''
    xhrList:XMLHttpRequest[] = []

    constructor(or: E_ScreenMode) {
        super(or == E_ScreenMode.horizontal ? UI_Login.createInstance() : UI_Login.createInstance());
        this.onUILoaded()
    }

    onUILoaded() {
        this.uiLogin = this.UI_Item as UI_Login
        fgui.GRoot.inst.addChild(this.UI_Item);
        this.uiLogin.m_password.on(Laya.Event.INPUT, this, this.onPwdChanged);
        this.uiLogin.m_username.on(Laya.Event.INPUT, this, this.onUSerNameChanged);
        this.c1 = this.uiLogin.m_c1;

        this.uiLogin.m_username.text = LocalUtil.stringForKey('account',"")
        // this.autoLogin()

        this.uiLogin.m_tourLoginBtn.onClick(this, this.tourLogin)
        this.uiLogin.m_trylog.onClick(this, this.clickTourBtn)

        //获取验证码

        //更换验证码
        this.uiLogin.m_code_img.onClick(this, () => {
            this.getCode()
        })

        this.uiLogin.m_tourCode_img.onClick(this, () => {
            this.getTourCode()
        })


        //忘记密码
        // this.uiLogin.m_fpasword.onClick(this, this.onCustomerClick)
        this.uiLogin.m_fpasword.onClick(this, ()=>{
            this.c1.selectedIndex = 2
            this.uiLogin.m_select_btn.m_c1.selectedIndex = this.c1.selectedIndex;
        })


        //切换注册
        this.uiLogin.m_select_btn.onClick(this, () => {
            this.c1.selectedIndex = this.c1.selectedIndex == 1 ? 0 : 1;
            this.uiLogin.m_select_btn.m_c1.selectedIndex = this.c1.selectedIndex;
        })



        //登录
        this.uiLogin.m_loginBtn.onClick(this, () => {
            this.login()
        })

        //注册
        this.uiLogin.m_registerBtn.onClick(this, () => this.register())
        this.getCode()


        //authcode
        this.uiLogin.m_authCode.grayed = false;
        this.uiLogin.m_authCode.m_time.text = I18nHelper.t('emailCode')
        this.uiLogin.m_authCode.onClick(this,()=>{
            if(this.uiLogin.m_authCode.grayed)return;
            this.clickAuthCode();
        })

        //findPwd
        this.uiLogin.m_loginBtn2.text = I18nHelper.t('findPwd')
        this.uiLogin.m_loginBtn2.onClick(this,()=>{
            this.findPwd();
        })

        //backLogin
        this.uiLogin.m_loginBtn3.text = I18nHelper.t('backLogin')
        this.uiLogin.m_loginBtn3.onClick(this,()=>{

            this.c1.selectedIndex = 0
        })
        this.uiLogin.m_country_btn.value = LocalUtil.stringForKey("lang", I18nHelper.getLoaclLang())
        this.uiLogin.m_country_btn.on(fairygui.Events.STATE_CHANGED, this, this.onChanged)

        if (LocalUtil.getQueryString("invitationCode")) {
            this.c1.selectedIndex = 1;
        }

        this.uiLogin.m_logo.url = GlobalParameterManager.getInstance().getLogoUrl();

    }

    onChanged() {
        console.log("当前选中的值：", this.uiLogin.m_country_btn.value);
        LocalUtil.setString(this.uiLogin.m_country_btn.value, "lang")
        window.location.reload()
    }

    getEmailCode(){
        let data = {
            "email":this.uiLogin.m_username.text
        }
        let xhr = http.sendEmailCode(data,(res: any) => {
            if (res.code == 200) {
                console.log("发送验证码成功")
            } else {
                console.log("发送验证码失败",res.msg)
                UIHelper.showMsg(I18nHelper.t(res.msg))
            }
        })
        this.xhrList.push(xhr)
        
    }
    getCode() {
       let xhr = http.getVerifyCode((res: any) => {
            if (res.code == 200) {
                if (this.uiLogin) {

                    this.uiLogin.m_code_img && (this.uiLogin.m_code_img.url = "data:image/gif;base64," + res.data.img)
                    this.uuid = res.data.uuid
                    this.uiLogin.m_code.text = ""
                }
            } else {
                UIHelper.showMsg(I18nHelper.t(res.msg))
            }
        })
        this.xhrList.push(xhr)
    }


    onPwdChanged() {
        //console.log("输入改变了", this.uiLogin.m_password.text);
    }


    onUSerNameChanged() {
        // console.log("输入改变了", this.uiLogin.m_password.text);
    }



    // autoLogin() {
    //     if (HttpHelper.getInstance().isJumpLogin) {
    //         return
    //     }
    //     let token = LocalUtil.getQueryString("token")
    //     console.log("urltoken:", token)
    //     if (token) {
    //         this.setUserToken(token)
    //     }

    //     if (LocalUtil.stringForKey("userToken")) {
    //         this.setUserToken(LocalUtil.stringForKey("userToken"))
    //         let xhr = http.getUserInfo((data: any) => {
    //             if (data.code == 200) {
    //                 PlayerDataManager.getInstance().setPlayerInfo(data.data)
    //                 let roomId = LocalUtil.getQueryString("roomId")

    //                 if (roomId) {
    //                     Laya.Scene.open(Router.Game, true, null, Laya.Handler.create(this, (scene: Laya.Scene) => {
    //                         let gameScene: GameScene = scene.getComponent(GameScene)
    //                         gameScene.onOpened({ "roomId": roomId })
    //                     }))
    //                 } else {
    //                     Laya.Scene.open(Router.Home, true, null, Laya.Handler.create(this, () => {
    //                     }))
    //                 }
    //             } else {
    //                 LocalUtil.setString('', "userToken")
    //                 UIHelper.showMsg(I18nHelper.t(data.msg))
    //                 this.getCode()
    //             }
    //             console.log("用户个人信息", data.data);
    //         })
    //         this.xhrList.push(xhr)
    //     } else {
    //         this.tourLogin()
    //     }
    // }

    
    setUserToken(token: string) {
        NetManager.getInstance().token = token
        LocalUtil.setString(token, "userToken")
    }


    //登录
    login() {
    
        if (this.uiLogin.m_username.text == "" || this.uiLogin.m_password.text == "" || this.uiLogin.m_code.text == "") {
            UIHelper.showMsg(I18nHelper.t('loginMsg'))
            return
        }


        UIHelper.showWaitModal(true)
        let xhr = http.login({
            phone: this.uiLogin.m_username.text,
            password: this.uiLogin.m_password.text,
            code: this.uiLogin.m_code.text,
            uuid: this.uuid,
            // phone: '13300000000',
            // password: '111111',
            // phone: '123456',
            // password: '123456',
        }, (data: any) => {
            if (data.code == 200) {
                LocalUtil.setString("0", "tour")

                this.setUserToken(data.data)
                let xhr = http.getUserInfo((data: any) => {
                    if (data.code == 200) {
                        LocalUtil.setString(this.uiLogin.m_username.text,'account')
                        PlayerDataManager.getInstance().setPlayerInfo(data.data)
                        Laya.Scene.open(Router.Home, true, null, Laya.Handler.create(this, () => {
                        }))
                    } else {
                        UIHelper.showMsg(I18nHelper.t(data.msg))
                        this.getCode()
                    }
                    console.log("用户个人信息", data.data);
                    HttpHelper.getInstance().isJumpLogin = false

                })
                this.xhrList.push(xhr)

            } else {
                this.getCode()
                UIHelper.showMsg(I18nHelper.t(data.msg))
            }
            UIHelper.closeWaitModal()
        })
        this.xhrList.push(xhr)
    }

    //注册
    register() {
        

        if (this.uiLogin.m_username.text == "" || this.uiLogin.m_authCodeInput.text == ""|| !this.uiLogin.m_username.text.includes("@")|| this.uiLogin.m_reg_password.text == "" || this.uiLogin.m_name.text == "" || this.uiLogin.m_reg_password2.text == "") {
            UIHelper.showMsg(I18nHelper.t('regMsg'))
            return
        }
        let invitationCode = LocalUtil.getQueryString("invitationCode")
        console.log(invitationCode);
        let data: any = {
            phone: this.uiLogin.m_username.text,
            password: this.uiLogin.m_reg_password.text,
            name: this.uiLogin.m_name.text,
            checkPassword: this.uiLogin.m_reg_password2.text,
            code: this.uiLogin.m_authCodeInput.text,
        }
        if (invitationCode) {
            data = {
                phone: this.uiLogin.m_username.text,
                password: this.uiLogin.m_reg_password.text,
                name: this.uiLogin.m_name.text,
                invitationCode: invitationCode,
                checkPassword: this.uiLogin.m_reg_password2.text,
                code: this.uiLogin.m_authCodeInput.text,
            }
        }
        //
        let xhr = http.register(data, (data: any) => {
            if (data.code == 200) {
                this.c1.selectedIndex = 0;

            } else {
                this.getCode()

                UIHelper.showMsg(I18nHelper.t(data.msg))
            }
        })
        this.xhrList.push(xhr)
    }
    numberToTime(num: number): string {
        let hours = Math.floor(num / 10000);
        let minutes = Math.floor((num % 10000) / 100);
        let seconds = Math.floor(num % 100);
     
        let hours1 = hours < 10 ? '0' + hours : hours.toString();
        let minutes1 = minutes < 10 ? '0' + minutes : minutes.toString();
        let seconds1 = seconds < 10 ? '0' + seconds : seconds.toString();
        return `${minutes1}:${seconds1}`;
    }
    clickAuthCode(){
       
        if (this.uiLogin.m_username.text == "" || !this.uiLogin.m_username.text.includes("@")) {
            UIHelper.showMsg(I18nHelper.t('regMsg'))
            return
        }
        this.uiLogin.m_authCode.grayed = true;
        let time = 60;
        let timeFun = ()=>{
            if(time == 0){
                this.uiLogin.m_authCode.grayed = false;
                this.uiLogin.m_authCode.m_time.text = I18nHelper.t("reEmailCode");
            }else{
                time--;
                this.uiLogin.m_authCode.m_time.text =  this.numberToTime(time);
                setTimeout(()=>{
                    timeFun();
                },1000)
            }
        }
        timeFun();
        this.getEmailCode();
     
    }
    findPwd(){
     
        if (this.uiLogin.m_username.text == "" 
            ||!this.uiLogin.m_username.text.includes("@")
            ||this.uiLogin.m_reg_password.text == ""  || this.uiLogin.m_reg_password2.text == ""
            ||this.uiLogin.m_authCodeInput.text == ""
        ) {
            UIHelper.showMsg(I18nHelper.t('loginMsg'))
            return
        }
        let data: any = {
           "email":this.uiLogin.m_username.text ,
            "code": this.uiLogin.m_authCodeInput.text,
            "newPwd": this.uiLogin.m_reg_password.text, 
            "checkPwd": this.uiLogin.m_reg_password2.text
        }
        let xhr = http.findPwd(data, (data: any) => {
            if (data.code == 200) {

                this.c1.selectedIndex = 0;
            } else {
              
                UIHelper.showMsg(I18nHelper.t(data.msg))
            }
        })
        this.xhrList.push(xhr)
    }


    getTourCode(){
        let xhr = http.getVerifyCode((res: any) => {
            if (res.code == 200) {
                if (this.uiLogin) {
                    this.uiLogin.m_tourCode_img && (this.uiLogin.m_tourCode_img.url = "data:image/gif;base64," + res.data.img)
                    this.tourUuid = res.data.uuid
                    this.uiLogin.m_tourCode.text = ""
                }
            } else {
                UIHelper.showMsg(I18nHelper.t(res.msg))
            }
        })
        this.xhrList.push(xhr)
    }


    clickTourBtn(){
        this.getTourCode()
    }
   

    tourLogin() {
        UIHelper.showWaitModal(true)
     // let tourKey =  LocalUtil.stringForKey("tourKey")
        // if (!tourKey) {
        //     LocalUtil.setString(new Date().getTime().toString()+Math.random().toString(36).substr(2, 5),"tourKey") 
        //     tourKey =  LocalUtil.stringForKey("tourKey")
        // }

        let tourKey = new Date().getTime().toString()+Math.random().toString(36).substr(2, 5)

        let xhr = http.login({
            isTour: 2,
            tourKey : tourKey,
            code: this.uiLogin.m_tourCode.text,
            uuid: this.tourUuid,
        }, (data: any) => {
            if (data.code == 200) {
                // this.setUserToken(data.data)
                LocalUtil.setString("", "userToken")
                NetManager.getInstance().token = data.data

                let xhr = http.getUserInfo((data: any) => {
                    if (data.code == 200) {
                        PlayerDataManager.getInstance().setPlayerInfo(data.data)
                        Laya.Scene.open(Router.Home, true, null, Laya.Handler.create(this, () => {
                        }))
                    } else {
                        UIHelper.showMsg(I18nHelper.t(data.msg))
                        this.getTourCode()
                    }
                    console.log("用户个人信息", data.data);
                    HttpHelper.getInstance().isJumpLogin = false

                })
                this.xhrList.push(xhr)
            } else {
                this.getTourCode()
                UIHelper.showMsg(I18nHelper.t(data.msg))
            }
            UIHelper.closeWaitModal()
        })
        this.xhrList.push(xhr)
    }



    //忘记密码    客服列表
    customerWindow: CustomerWindow = null
    async onCustomerClick() {
        if (!ConfigManager.getInstance().Customer) {
            UIHelper.showMsg(I18nHelper.t('noService'))
            return;
        }
        if (ConfigManager.getInstance().Customer.length > 0) {
            if (!this.customerWindow) {
                this.customerWindow = new CustomerWindow(ConfigManager.getInstance().Customer)
            }
            UIHelper.showWindow(this.customerWindow)
        }
        else {
            UIHelper.showMsg(I18nHelper.t('noService'))
        }
    }



    destroy() {
        this.xhrList.forEach((xhr)=>{
            xhr.abort()
        })
        console.log("LoginView销毁了")
        this.uiLogin.dispose();
    }
}