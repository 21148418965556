import { IGameRecord } from "../../config/interface";
import UI_BigRecordRow from "../../fgui/windows/UI_BigRecordRow";
import UI_GameRecordRow from "../../fgui/windows/UI_GameRecordRow";
import UI_GameRecordWindow from "../../fgui/windows/UI_GameRecordWindow";
import UI_LHBallBtn from "../../fgui/windows/UI_LHBallBtn";
import UI_LHRecordRow from "../../fgui/windows/UI_LHRecordRow";
import UI_NumBallBtn from "../../fgui/windows/UI_NumBallBtn";
import UI_NumBallBtn3 from "../../fgui/windows/UI_NumBallBtn3";
import UI_NumRecordRow from "../../fgui/windows/UI_NumRecordRow";
import UI_RecordBall from "../../fgui/windows/UI_RecordBall";
import UI_SumRecordRow from "../../fgui/windows/UI_SumRecordRow";
import I18nHelper from "../../helper/I18nHelper";
import { http } from "../../net/Http";

export class GameRecord {

    ui: UI_GameRecordWindow
    data: IGameRecord[] = []
    //刷新头部
    private _header: fairygui.GComponent;
    //刷新头部控制器
    private _header_c1: fgui.Controller;
    private _total: number = 0;
    private roomId: number
    //页码
    private _pageNum: number = 1;
    xhrList: XMLHttpRequest[] = [];

    private isBall_8 = false;
    constructor(ui: UI_GameRecordWindow, roomId: number, ballNum: number) {
        if (ballNum == 8) {
            this.isBall_8 = true
        }
        console.log("111111", this.isBall_8);
        this.roomId = roomId
        this.ui = ui
        this.onUILoaded()
    }


    onUILoaded() {
        this._header = this.ui.m_list.scrollPane.header
        this._header_c1 = this._header.getController("c1");
        this._header.on(fgui.Events.SIZE_CHANGED, this, this.onSizeChanged);
        this.ui.m_list.on(fgui.Events.PULL_DOWN_RELEASE, this, this.onPullDownToRefresh);
        this.ui.m_list.on(fgui.Events.PULL_UP_RELEASE, this, this.onPullUpToRefresh);
        // this.ui.m_list.setVirtual()

        this.ui.m_list.itemRenderer = Laya.Handler.create(this, this.renderListItem, null, false);






        // this.ui.m_closeButton.onClick(this,()=>{
        //     this.ui.visible = false
        // })
        this.ui.m_threndSelect.on(fairygui.Events.STATE_CHANGED, this, this.onSelectChanged);
        this.onSelectChanged()


        this.ui.m_NumThrend.m_list.itemRenderer = Laya.Handler.create(this, this.numRenderListItem, null, false);
        this.ui.m_LHThrend.m_list.itemRenderer = Laya.Handler.create(this, this.lhRenderListItem, null, false);
        this.ui.m_BigThrend.m_list.itemRenderer = Laya.Handler.create(this, this.bigRenderListItem, null, false);
        this.ui.m_SumThrend.m_list.itemRenderer = Laya.Handler.create(this, this.sumRenderListItem, null, false);

        this.getList()
    }

    showWindow() {
        this.getList()
    }

    onSelectChanged() {
        this.ui.m_NumThrend.visible = false
        this.ui.m_LHThrend.visible = false
        this.ui.m_BigThrend.visible = false
        this.ui.m_SumThrend.visible = false
        switch (this.ui.m_threndSelect.value) {
            case "1":
                this.ui.m_NumThrend.visible = true
                break;
            case "2":
                this.ui.m_NumThrend.visible = true
                break;
            case "3":
                this.ui.m_NumThrend.visible = true
                break;
            case "4":
                this.ui.m_NumThrend.visible = true
                break;
            case "5":
                this.ui.m_NumThrend.visible = true
                break;
            case "6":
                this.ui.m_NumThrend.visible = true
                break;
            case "7":
                this.ui.m_NumThrend.visible = true
                break;
            case "8":
                this.ui.m_NumThrend.visible = true
                break;
            case "9":
                this.ui.m_NumThrend.visible = true
                break;
            case "10":
                this.ui.m_NumThrend.visible = true
                break;
            case "11":
                this.ui.m_LHThrend.visible = true
                break;
            case "12":
                this.ui.m_BigThrend.visible = true
                break;
            case "13":
                this.ui.m_SumThrend.visible = true
            default:
                break;
        }
        this.changeNumThrend()
        this.changeLHThrend()
        this.changeSumThrend()
        this.changeBighrend()
    }


    changeNumThrend() {
        this.ui.m_NumThrend.m_list.numItems = this.data.length

        let points: {
            x: number,
            y: number,
            ball: UI_NumBallBtn | UI_NumBallBtn3
        }[] = []
        this.ui.m_NumThrend.m_list._children.forEach((item: any, index) => {
            let tmpList = this.isBall_8 ? item.m_ballList : item.m_ballList2
            console.log(tmpList.name)
            tmpList._children.forEach((item: any) => {
                if (item.m_red.visible) {
                    let y = 50 * index
                    points.push({
                        x: item.x,
                        y: y,
                        ball: item
                    })

                }
            })
        })
        let sprite = new Laya.Sprite()
        var g = new Laya.Graphics();
        // 遍历坐标点，并绘制线条
        for (var i = 0; i < points.length - 1; i++) {
            var p0 = points[i];
            var p1 = points[i + 1];

            const x1 = p0.x,
                y1 = p0.y
            const x2 = p1.x,
                y2 = p1.y

            // 长度
            const distance = Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1))
            // 弧度
            const radius = Math.atan2(y2 - y1, x2 - x1)

            points[i].ball.m_line.height = distance
            points[i].ball.m_line.width = 2
            points[i].ball.m_line.rotation = radius * (180 / Math.PI) + 270



            g.drawLine(p0.x, p0.y, p1.x, p1.y, "#ff0000", 2); // 从p0到p1画一条红色线，宽度为2
        }

        sprite.graphics = g;
    }
    changeLHThrend() {
        this.ui.m_LHThrend.m_list.numItems = this.data.length

    }
    changeBighrend() {
        this.ui.m_BigThrend.m_list.numItems = this.data.length
    }
    changeSumThrend() {
        this.ui.m_SumThrend.m_list.numItems = this.data.length
    }

    private numRenderListItem(index: number, item: UI_NumRecordRow): void {

        let num = Number(this.ui.m_threndSelect.value)
        if (num > 10) {
            return
        }
        item.m_ballList.visible = this.isBall_8;
        item.m_ballList2.visible = !this.isBall_8
        let obj = this.data[index]
        item.m_code.text = obj?.nperCode
        // console.log("obj.result:",obj.result);


        if (obj.result) {
            item.m_no.visible = false

            if (typeof obj.result == "string") {
                obj.result = JSON.parse(obj.result)
            }
            let tmpList = this.isBall_8 ? item.m_ballList : item.m_ballList2;
            tmpList._children.forEach((item: any, index) => {
                if (index == (Number(obj.result[num - 1])-1)) {
                    item.m_red.visible = true
                    item.titleColor = "#FFFFFF"
                } else {
                    item.m_red.visible = false
                    item.m_line.width = 0
                    item.m_line.height = 0
                    item.titleColor = "#ffffff"
                }
            })
        } else {
            item.m_no.visible = true
            item.m_ballList.visible = false
            item.m_ballList2.visible = false
            if (index == 0 && !obj.result) {
                item.m_no.text = I18nHelper.t('prizeDraw')
            }
        }

    }
    private lhRenderListItem(index: number, item: UI_LHRecordRow): void {
        let num = Number(this.ui.m_threndSelect.value)

        if (num != 11) {
            return
        }

        item.m_ballList.visible = this.isBall_8;
        item.m_ballList2.visible = !this.isBall_8
        let obj = this.data[index]
        item.m_code.text = obj?.nperCode
        // console.log("obj.result:",obj.result);

        if (obj.result) {
            item.m_no.visible = false

            if (typeof obj.result == "string") {
                obj.result = JSON.parse(obj.result)
            }

            let tmpList = this.isBall_8 ? item.m_ballList : item.m_ballList2;
            tmpList._children.forEach((item: any, i) => {
                if (obj.result[i] > obj.result[obj.result.length - 1 - i]) {
                    item.title = I18nHelper.t('dragon')
                    item.titleColor = "#FF3D3D"

                } else {
                    item.title = I18nHelper.t('tiger')
                    item.titleColor = "#ffffff"
                }

            })
        } else {
            item.m_no.visible = true
            item.m_ballList.visible = false
            item.m_ballList2.visible = false
            if (index == 0 && !obj.result) {
                item.m_no.text = I18nHelper.t('prizeDraw')
            }
        }

    }
    private bigRenderListItem(index: number, item: UI_BigRecordRow): void {
        let num = Number(this.ui.m_threndSelect.value)
        if (num != 12) {
            return
        }

        let obj = this.data[index]
        item.m_code.text = obj?.nperCode
        // console.log("obj.result:",obj.result);

        if (obj.result) {
            item.m_no.visible = false

            if (typeof obj.result == "string") {
                obj.result = JSON.parse(obj.result)
            }

            let tmpList = this.isBall_8 ? item.m_ballList : item.m_ballList2;
            item.m_ballList.visible = this.isBall_8;
            item.m_ballList2.visible = !this.isBall_8;
            tmpList._children.forEach((item: any, index) => {
                if (Number(obj.result[index]) % 2 === 0) {
                    item.m_double.text = I18nHelper.t('even')
                    item.m_double.color = "#FF3D3D"
                } else {
                    item.m_double.text = I18nHelper.t('odd')
                    item.m_double.color = "#ffffff"
                }

                if (Number(obj.result[index]) > 4) {
                    item.m_big.text = I18nHelper.t('big')
                    item.m_big.color = "#FF3D3D"
                } else {
                    item.m_big.text = I18nHelper.t('small')
                    item.m_big.color = "#ffffff"
                }
            })
        } else {
            item.m_no.visible = true
            item.m_ballList.visible = false
            item.m_ballList2.visible = false
            if (index == 0 && !obj.result) {
                item.m_no.text = I18nHelper.t('prizeDraw')
            }
        }

    }
    private sumRenderListItem(index: number, item: UI_SumRecordRow): void {
        let num = Number(this.ui.m_threndSelect.value)
        if (num != 13) {
            return
        }

        let obj = this.data[index]
        item.m_code.text = obj?.nperCode
        console.log("obj.result:", obj.result);
        let tmpList = this.isBall_8 ? item.m_ballList : item.m_ballList2;
        if (obj.result) {
            item.m_no.visible = false
            if (typeof obj.result == "string") {
                obj.result = JSON.parse(obj.result)
            }
            item.m_ballList.visible = this.isBall_8;
            item.m_ballList2.visible = !this.isBall_8;
            (tmpList._children[0] as UI_LHBallBtn).title = obj.result[0] + obj.result[1];
            (tmpList._children[1] as UI_LHBallBtn).title = obj.result[0] + obj.result[1] + obj.result[2] + obj.result[3];
            (tmpList._children[2] as UI_LHBallBtn).title = obj.result[0] + obj.result[1] + obj.result[2] + obj.result[3] + obj.result[4] + obj.result[5];


            (tmpList._children[3] as UI_LHBallBtn).title = obj.result[0] + obj.result[1] + obj.result[2] + obj.result[3] + obj.result[4] + obj.result[5] + obj.result[6] + obj.result[7];

            if (!this.isBall_8) {
                (tmpList._children[4] as UI_LHBallBtn).title = obj.result[0] + obj.result[1] + obj.result[2] + obj.result[3] + obj.result[4] + obj.result[5] + obj.result[6] + obj.result[7] + obj.result[8] + obj.result[9];

            }

        } else {
            item.m_no.visible = true
            item.m_ballList.visible = false
            item.m_ballList2.visible = false
            if (index == 0 && !obj.result) {
                item.m_no.text = I18nHelper.t('prizeDraw')
            }
        }

    }



    private renderListItem(index: number, item: UI_GameRecordRow): void {
        let obj = this.data[index]
        item.m_code.text = obj?.nperCode
        // console.log("obj.result:",obj.result);

        if (obj.result) {
            item.m_no.visible = false
            item.m_ballList.visible = true

            if (typeof obj.result == "string") {
                obj.result = JSON.parse(obj.result)
            }
            item.m_ballList.itemRenderer = Laya.Handler.create(this, this.renderBallListItem, [obj.result], false);
            item.m_ballList.numItems = obj.result.length
        } else {
            item.m_no.visible = true
            item.m_ballList.visible = false
            if (index == 0) {
                item.m_no.text = I18nHelper.t('prizeDraw')
            }
        }

    }


    private renderBallListItem(data: number[], index: number, item: UI_RecordBall): void {
        item.width = (item.parent.width - 20 * data.length) / data.length
        item.m_ball.url = fgui.UIPackage.getItemURL("game", "ball" + data[index]);
    }



    getList() {
        let xhr = http.lotteryRecord({
            // isSettled:0 | 1

            date: <any>(<any>window).dayjs().format('YYYY-MM-DD'),
            roomId: this.roomId
        }, (data: any) => {
            if (data.code == 200) {

                this.data = data.data
                this._total = data.total
                console.log("开奖记录:", data);

                this.ui.m_list.numItems = this.data.length
                this.ui.m_NumThrend.m_list.numItems = this.data.length
                this.ui.m_LHThrend.m_list.numItems = this.data.length
                this.ui.m_BigThrend.m_list.numItems = this.data.length
                this.ui.m_SumThrend.m_list.numItems = this.data.length

                this.changeNumThrend()

                this.ui.m_NumThrend.m_title.m_ballList.visible = this.isBall_8
                this.ui.m_NumThrend.m_title.m_ballList2.visible = !this.isBall_8
                this.ui.m_LHThrend.m_title.m_ballList.visible = this.isBall_8
                this.ui.m_LHThrend.m_title.m_ballList2.visible = !this.isBall_8
                this.ui.m_SumThrend.m_title.m_ballList.visible = this.isBall_8
                this.ui.m_SumThrend.m_title.m_ballList2.visible = !this.isBall_8
                this.ui.m_BigThrend.m_title.m_ballList.visible = this.isBall_8
                this.ui.m_BigThrend.m_title.m_ballList2.visible = !this.isBall_8

                if (this.isBall_8) {
                    let indexArr: string[] = [];
                    this.ui.m_threndSelect.values.forEach((element, index) => {
                        if (element == "9" || element == "10") {
                            console.log(this.ui.m_threndSelect)
                            indexArr.push(index.toString());
                        }
                    });
                    const newLength = this.ui.m_threndSelect.items.length - indexArr.length;
                    const newItems: string[] = new Array(newLength);
                    let newIndex = 0;
                    for (let i = 0; i < this.ui.m_threndSelect.items.length; i++) {
                        if (indexArr.includes(i.toString())) {
                            continue;
                        }
                        newItems[newIndex++] = this.ui.m_threndSelect.items[i];
                    }

                    const newValues: string[] = new Array(newLength);
                    newIndex = 0;
                    for (let i = 0; i < this.ui.m_threndSelect.values.length; i++) {
                        if (indexArr.includes(i.toString())) {
                            continue;
                        }
                        newValues[newIndex++] = this.ui.m_threndSelect.values[i];
                    }
                    this.ui.m_threndSelect.items = newItems;
                    this.ui.m_threndSelect.values = newValues;
                }



            }
        })

        this.xhrList.push(xhr)
    }

    //下拉加载
    private async onPullDownToRefresh(evt: Laya.Event): Promise<void> {
        console.log('准备');
        // console.log(header);

        if (this._header_c1.selectedIndex == 1) {
            this._header_c1.selectedIndex = 2;
            this.ui.m_list.scrollPane.lockHeader(this._header.sourceHeight);
            this._pageNum = 1
            await this.getList();
            if (this.ui.isDisposed) {
                return;
            }
            this._header_c1.selectedIndex = 0;
            this.ui.m_list.scrollPane.lockHeader(0);
        }
    }

    //监听头部刷新控制器
    onSizeChanged(): void {
        if (this._header_c1.selectedIndex == 2)
            return;
        if (this._header.height > this._header.sourceHeight)
            this._header_c1.selectedIndex = 1;
        else
            this._header_c1.selectedIndex = 0;
    }

    //上拉加载
    private async onPullUpToRefresh(evt: Laya.Event): Promise<void> {
        var footer: fgui.GComponent = this.ui.m_list.scrollPane.footer.asCom;
        footer.getController("c1").selectedIndex = 1;
        this.ui.m_list.scrollPane.lockFooter(footer.sourceHeight);

        if (this.data.length < this._total) {
            this._pageNum++
            //Simulate a async resquest
            var result = await this.getList();
            if (this.ui.isDisposed)
                return;

            //Refresh completed
            footer.getController("c1").selectedIndex = 0;
            this.ui.m_list.scrollPane.lockFooter(0);
        } else {
            this.ui.m_list.scrollPane.lockFooter(0);
            return;
        }

    }


    destroy() {
        this.xhrList.forEach((xhr) => {
            xhr.abort()
        })
    }
}