import { E_ScreenMode } from "../../config/Enum";
import gameBinder from "../../fgui/game/gameBinder";
import UI_Loading from "../../fgui/loading/UI_Loading";
import UI_Loading_V from "../../fgui/loading/UI_Loading_V";
import loadingBinder from "../../fgui/loading/loadingBinder";
import loginBinder from "../../fgui/login/loginBinder";
import homeBinder from "../../fgui/home/homeBinder";
import activeBinder from "../../fgui/active/activeBinder";
import { http } from "../../net/Http";
import ResourceManager from "../../util/ResourceManager";
import { Router } from "../../util/router";
import walletBinder from "../../fgui/wallet/walletBinder";
import meBinder from "../../fgui/me/meBinder";
import modifyBinder from "../../fgui/modify/modifyBinder";
import backwaterBinder from "../../fgui/backwater/backwaterBinder";
import commonBinder from "../../fgui/common/commonBinder";
import chatBinder from "../../fgui/chat/chatBinder";
import chargeBinder from "../../fgui/charge/chargeBinder";
import cashBinder from "../../fgui/cash/cashBinder";
import pay_modifyBinder from "../../fgui/pay_modify/pay_modifyBinder";
import windowsBinder from "../../fgui/windows/windowsBinder";
import betting_orderBinder from "../../fgui/betting_order/betting_orderBinder";
import wallet_recordBinder from "../../fgui/wallet_record/wallet_recordBinder";
import bind_cardBinder from "../../fgui/bind_card/bind_cardBinder";
import bind_walletBinder from "../../fgui/bind_wallet/bind_walletBinder";
import { UIHelper } from "../../helper/UIHelper";
import balance_recordBinder from "../../fgui/balance_record/balance_recordBinder";
import bind_withdrawBinder from "../../fgui/bind_withdraw/bind_withdrawBinder";
import agentBinder from "../../fgui/agent/agentBinder";
import LocalUtil from "../../util/LocalUtil";
import langBinder from "../../fgui/lang/langBinder";
import I18nHelper from "../../helper/I18nHelper";
import updateUserBinder from "../../fgui/updateUser/updateUserBinder";
import HttpHelper from "../../helper/HttpHelper";
import PlayerDataManager from "../../model/PlayerDataManager";
import NetManager from "../../net/NetManager";
import ConfigManager from "../../helper/ConfigManager";
import GlobalParameterManager from "../../model/GlobalParameterManager";

export class Loading {
    private reloadNums: number = 0
    private or: E_ScreenMode
    private uiLoading: UI_Loading | UI_Loading_V;
    constructor(or: E_ScreenMode) {
        // this.or = or
        // fgui.UIPackage.loadPackage("resources/ui/loading", Laya.Handler.create(this, this.onUILoaded));


        this.or = or

        let languageXml: Array<any> = [
            {
                url: "resources/i18n/en.xml",
                type: Laya.Loader.TEXT
            },
            // {
            //     url: "resources/i18n/zh.xml",
            //     type: Laya.Loader.TEXT
            // },
            // {
            //     url: "resources/i18n/ko.xml",
            //     type: Laya.Loader.TEXT
            // },
            // {
            //     url: "resources/i18n/km.xml",
            //     type: Laya.Loader.TEXT
            // },
            // {
            //     url: "resources/i18n/zhF.xml",
            //     type: Laya.Loader.TEXT
            // },
            // {
            //     url: "resources/i18n/vi.xml",
            //     type: Laya.Loader.TEXT
            // },
            // {
            //     url: "resources/i18n/th.xml",
            //     type: Laya.Loader.TEXT
            // },
            // {
            //     url: "resources/i18n/ms.xml",
            //     type: Laya.Loader.TEXT
            // },
            {
                url: "resources/i18n/pt.xml",
                type: Laya.Loader.TEXT
            }
        ]


        // Laya.loader.load("resources/i18n/en.xml", Laya.Loader.XML).then((res: Laya.XML) => {
        //         console.log(res);

        // });
        // Laya.loader.load("resources/i18n/ARIALUNI.TTF", {
        //     type: Laya.Loader.TEXT
        // }, null).then((res) => {
        //    console.log(res);

        // })

        Laya.loader.load(languageXml, {
            type: Laya.Loader.TEXT
        }, null).then((res) => {
            let lang = LocalUtil.stringForKey("lang", I18nHelper.getLoaclLang())
            if (lang == "zh") {
                lang = "pt"
                LocalUtil.setString(lang,"lang")
            }
            let xmlRes = Laya.loader.getRes(`resources/i18n/${lang}.xml`, Laya.Loader.TEXT)
            console.log(xmlRes);

            if (xmlRes) {
                fgui.UIPackage.setStringsSource(xmlRes.data)
                fgui.UIPackage.loadPackage("resources/ui/loading", Laya.Handler.create(this, this.onUILoaded));
            }
        })
    }




    onUILoaded() {
        UIHelper.createIFrame()

        commonBinder.bindAll()
        loadingBinder.bindAll()
        loginBinder.bindAll()
        homeBinder.bindAll()
        activeBinder.bindAll()
        walletBinder.bindAll()
        meBinder.bindAll()
        gameBinder.bindAll()
        modifyBinder.bindAll()
        backwaterBinder.bindAll()
        chatBinder.bindAll()
        chargeBinder.bindAll()
        cashBinder.bindAll()
        pay_modifyBinder.bindAll()
        windowsBinder.bindAll()
        betting_orderBinder.bindAll()
        wallet_recordBinder.bindAll()
        bind_cardBinder.bindAll()
        bind_walletBinder.bindAll()
        balance_recordBinder.bindAll()
        bind_withdrawBinder.bindAll()
        agentBinder.bindAll()
        langBinder.bindAll()
        updateUserBinder.bindAll()

        this.getCustomerData()
        this.uiLoading = this.or == E_ScreenMode.horizontal ? UI_Loading_V.createInstance() : UI_Loading_V.createInstance()
        fgui.GRoot.inst.addChild(this.uiLoading);
        let pro = this.uiLoading.m_LoadingProgressBar
        pro.value = 0
        let foldersToLoad = [
            'resources/ui/active',
            'resources/ui/cash',
            'resources/ui/common',
            'resources/ui/game',
            'resources/ui/home',
            'resources/ui/login',
            'resources/ui/me',
            'resources/ui/home',
            'resources/ui/active',
            'resources/ui/wallet',
            'resources/ui/windows',
            'resources/ui/modify',
            'resources/ui/backwater',
            'resources/ui/chat',
            'resources/ui/charge',
            'resources/ui/pay_modify',
            'resources/ui/betting_order',
            'resources/ui/wallet_record',
            'resources/ui/bind_card',
            'resources/ui/bind_wallet',
            'resources/ui/balance_record',
            'resources/ui/bind_withdraw',
            'resources/ui/agent',
            'resources/ui/lang',
            'resources/ui/updateUser',
        ];
        this.loadRes(foldersToLoad)

        GlobalParameterManager.getInstance().initData(() => {
            this.uiLoading.m_logo.url = GlobalParameterManager.getInstance().getLogoUrl();
        })

    }
    loadRes(foldersToLoad: string[]) {
        try {
            ResourceManager.getInstance().loadResourcesInFolders(foldersToLoad, (progress) => {
                console.log('Loading progress: ' + progress);
                
                this.uiLoading.m_LoadingProgressBar.value = Math.ceil(progress * 100)
                this.updateProgress(this.uiLoading.m_LoadingProgressBar.value)
                if (progress == 1000) {
                     this.updateProgress(99.9)

                    setTimeout(() => {
                        let invitationCode = LocalUtil.getQueryString("invitationCode")
                        if (invitationCode) {
                            Laya.Scene.open(Router.Login, true, null, Laya.Handler.create(this, () => {
                                this.hideLoading(3)
                                fgui.GRoot.inst.removeChild(this.uiLoading, true)
                            }))
                            return
                        }

                        let token = LocalUtil.stringForKey("userToken")
                        if (token) {
                            NetManager.getInstance().token = token
                            LocalUtil.setString(token, "userToken")
                            let xhr = http.getUserInfo((data: any) => {
                                if (data.code == 200) {
                                    PlayerDataManager.getInstance().setPlayerInfo(data.data)
                                    Laya.Scene.open(Router.Home, true, null, Laya.Handler.create(this, () => {
                                        this.hideLoading(1)
                                        fgui.GRoot.inst.removeChild(this.uiLoading, true)
                                    }))
                                } else {
                                    Laya.Scene.open(Router.Login, true, null, Laya.Handler.create(this, () => {
                                        this.hideLoading(3)
                                        fgui.GRoot.inst.removeChild(this.uiLoading, true)
                                    }))
                                    LocalUtil.setString('', "userToken")
                                    UIHelper.showMsg(I18nHelper.t(data.msg))
                                }
                                console.log("用户个人信息", data.data);
                            })
                        } else {
                            Laya.Scene.open(Router.Login, true, null, Laya.Handler.create(this, () => {
                                this.hideLoading(7)
                                fgui.GRoot.inst.removeChild(this.uiLoading, true)
                            }))
                        }
                    }, 3000)
                }
            })
        } catch (error) {
            console.log("加载错误", error);
            this.reloadNums++
            console.log(`第${this.reloadNums}次尝试`);
            if (this.reloadNums > 5) {
                return
            } else {
                this.loadRes(foldersToLoad)
            }
        }
    }




    updateProgress(percentage: number) {
        var progressBar = document.querySelector('.progress') as HTMLElement;
        let text = document.querySelector('.progress-text') as HTMLElement;
        text.innerHTML = percentage + '%';
        progressBar.style.width = percentage + '%';
    }

    //获取客服列表
    getCustomerData() {
        let xhr = http.getCustomer((res: any) => {
            if (res.code == 200) {
                ConfigManager.getInstance().Customer = res.data
            }
            else {
            }
        })
    }




    tourLogin() {

        // let tourKey =  LocalUtil.stringForKey("tourKey")
        // if (!tourKey) {
        //     LocalUtil.setString(new Date().getTime().toString()+Math.random().toString(36).substr(2, 5),"tourKey") 
        //     tourKey =  LocalUtil.stringForKey("tourKey")
        // }

        let tourKey = new Date().getTime().toString()+Math.random().toString(36).substr(2, 5)

        let xhr = http.login({
            isTour: 2,
            tourKey : tourKey
        }, (data: any) => {
            if (data.code == 200) {
                // this.setUserToken(data.data)
                LocalUtil.setString("", "userToken")
                NetManager.getInstance().token = data.data

                let xhr = http.getUserInfo((data: any) => {
                    if (data.code == 200) {
                        PlayerDataManager.getInstance().setPlayerInfo(data.data)
                        Laya.Scene.open(Router.Home, true, null, Laya.Handler.create(this, () => {
                            this.hideLoading(4)
                            fgui.GRoot.inst.removeChild(this.uiLoading, true)
                        }))
                    } else {
                        Laya.Scene.open(Router.Login, true, null, Laya.Handler.create(this, () => {
                            this.hideLoading(5)
                            fgui.GRoot.inst.removeChild(this.uiLoading, true)
                        }))
                        UIHelper.showMsg(I18nHelper.t(data.msg))
                    }
                    console.log("用户个人信息", data.data);
                    HttpHelper.getInstance().isJumpLogin = false

                })
            } else {
                Laya.Scene.open(Router.Login, true, null, Laya.Handler.create(this, () => {
                    this.hideLoading(6)
                    fgui.GRoot.inst.removeChild(this.uiLoading, true)
                }))
            }
        })
    }


    hideLoading(index: number) {
        document.querySelector('.progress')?.remove()
        console.log(`index:${index}`);
        let layaCanvas = document.getElementById("layaCanvas")
        layaCanvas.style.display = ""
        let loadingVideo = document.getElementById("loadingVideo")
        loadingVideo.style.display = "none"
    }


    destroy() {
    }
}