/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_bet_info from "./UI_bet_info";

export default class UI_l_btn extends fgui.GButton {

	public m_bet_info:UI_bet_info;
	public m_limit:fgui.GTextField;
	public m_bg:fgui.GImage;
	public static URL:string = "ui://61ic9kfkmzb7z8y";

	public static createInstance():UI_l_btn {
		return <UI_l_btn>(fgui.UIPackage.createObject("game", "l_btn"));
	}

	protected onConstruct():void {
		this.m_bet_info = <UI_bet_info>(this.getChild("bet_info"));
		this.m_limit = <fgui.GTextField>(this.getChild("limit"));
		this.m_bg = <fgui.GImage>(this.getChild("bg"));
	}
}