/** This is an automatically generated class by FairyGUI. Please do not modify it. **/

import UI_bet_info from "./UI_bet_info";

export default class UI_bet_btn2 extends fgui.GButton {

	public m_limit:fgui.GTextField;
	public m_bet_info:UI_bet_info;
	public m_bg:fgui.GImage;
	public static URL:string = "ui://61ic9kfkgl40z7b";

	public static createInstance():UI_bet_btn2 {
		return <UI_bet_btn2>(fgui.UIPackage.createObject("game", "bet_btn2"));
	}

	protected onConstruct():void {
		this.m_limit = <fgui.GTextField>(this.getChild("limit"));
		this.m_bet_info = <UI_bet_info>(this.getChild("bet_info"));
		this.m_bg = <fgui.GImage>(this.getChild("bg"));
	}
}